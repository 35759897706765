import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { Core, Dashboard, AwsS3 } from 'uppy'

function fileUpload(element) {
  let target = element
  var container = document.getElementById('images')
  var counter = 0

  const uppy = Core({
    autoProceed: true,
    allowMultipleUploads: true,
    restrictions: {
      maxFileSize: 10485760, // 10MB
      allowedFileTypes: ["image/gif", "image/jpeg", "image/png"]
    },
    locale: {
      strings: {
        dropPaste: '%{browse}',
        browse: 'Choose Files'
      }
    }
  })

  uppy.use(Dashboard, {
    inline: true,
    target: target,
    showProgressDetails: true,
    proudlyDisplayPoweredByUppy: false
  })
  uppy.use(AwsS3, { companionUrl: "/" })

  uppy.on('file-added', (file) => {
    let index = (new Date().getTime() + counter++)
    uppy.setFileMeta(file.id, { index: index  })
  })

  uppy.on('complete', (result) => {
    result.successful.forEach(file => {
      appendUploadedFile(container, file)
    })
  })
}

function appendUploadedFile(container, file) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', `output[images_attributes][${file.meta['index']}][image]`)
  hiddenField.setAttribute('value', uploadedFileData(file))

  container.appendChild(hiddenField)
}

function uploadedFileData(file) {
  // construct uploaded file data in the format that Shrine expects
  return JSON.stringify({
    id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
    storage: 'cache',
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    }
  })
}

export default fileUpload
